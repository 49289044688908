export default {
  init() {
    // JavaScript to be fired on all pages

    // header search form trigger
    let searchForm = $(document).find('header .search-form');
    $('.trigger', searchForm).on('click', function() {
      $(searchForm).toggleClass('active');
    });

    // Slow scroll with anchors
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length > 0) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 500);
        }
      }
    });
    
    var $didomi_el = $('.didomi-trigger');
    if ($didomi_el.length > 0) {
      $didomi_el.on('click', function(e) {
        e.preventDefault();
        Didomi.preferences.show();
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
