//wkb hero slider
var $wkb_hero = $(document).find('.wkb-hero');
if( $wkb_hero.length > 0 ) {
  $.each($wkb_hero, function( index, banner ) {
    var $banner = $(banner);
    var $slides = $('.owl-carousel', $banner);
    if( $slides.length > 0 ) {
      var autoplay = $banner.data('heroAutoplay'),
          timeout = $banner.data('heroTimeout'),
          owl = $slides.owlCarousel({
            items: 1,
            dots: true,
            smartSpeed: 500,
            nav: false,
            loop: false,
            autoplay: autoplay,
            autoplayTimeout: timeout,
          });
    }
  });
}